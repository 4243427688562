import { useState } from 'react';
import SEO from '../components/common/seo/SEO';
import AppHeaderMobile from '../components/header/AppHeaderMobile';
import AppHeaderFull from '../components/header/AppHeaderFull';
import { FaLinkedin } from 'react-icons/fa';
import { css } from '@emotion/css';

const backgroundStyle = css`

/* background: url('/about/frisco-dark-background.webp')  ; */
background: url('/about/frisco-darkest-background-sharp-lossy.webp')  ;
  /* background-size: contain; */
  
 

  @media (min-width: 1px) and (max-width: 534px) {
  /* background-size: 30% 30%; */
  background-size: cover;
  background-position-x: 0rem;
  background-position-y: -2rem;
  /* background-position: 0% 20%; */
  max-height: 80vh;
  background-repeat: no-repeat;
  /* background-position: top center; */
  /* min-height: 80vh; */

}

/* complete */
@media (min-width: 535px) and (max-width: 1510px) {
  background-size: cover;
  background-position: 0% 20%;
  max-height: 80vh;
}



  /* For screens from 431px to 1920px */
  @media (min-width: 1511px) {
    background-size: contain;
    /* no repeat */
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: -2rem;
    min-height: 60vh;
    /* Add any other styles specific to this range */
  }
  /* Style for face-image class at min width 1536 and above  */
  @media (min-width: 1536px) {
    .face-image {
      /* move image to the right */
      position: relative;
      left: 10rem;
    }
  }


`;



const contentWrapper = css`
  position: relative;
  z-index: 1;
`;

const profileImageStyle = css`
  width: 200px;
  height: auto;
  border-radius: 5px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); */
  border: 2px solid #e2ba57; Gold border
  /* margin: 0 auto; */
`;

const mainContentStyle = css`
`;

const sectionTitle = css`
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: #e2ba57;
  text-transform: uppercase;
  border-bottom: 1px solid #e2ba57;
  display: inline-block;
`;

const accordionSection = css`
  margin-bottom: 2rem;
`;

const accordionItem = css`
  background-color: #2b3645;
  border: 1px solid #3a4758;
  margin-bottom: 1rem;
  border-radius: 5px;
`;

const accordionHeader = css`
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e2ba57;
  font-weight: bold;
`;

const accordionContent = css`
  padding: 1rem;
  color: #fffffc;
`;

const contactAndSocialStyle = css`
  display: flex-column;
  align-items: left;
  align-items: center; /* Center-align the entire contact block on small screens */

  @media (min-width: 767px) {
    align-items: flex-start; /* Left-align on wider screens */
  }

`;

const contactInfoStyle = css`
  color: #fffffc;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  text-align: left; /* Left-align content within the block */
  align-items: center; /* Center-align items on small screens */


  a {
    color: #e2ba57;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media (min-width: 767px) {
    align-items: flex-start; /* Left-align on wider screens */
  }



`;

const socialIcons = css`
  margin-top: 1rem;


  @media (max-width: 767px) {
    /* display: flex; */
    justify-content: flex-start;
    /* justify-content: flex-start; */
    width: 100%;
  }

  a {
    color: #e2ba57;
    margin-right: 1rem;
    font-size: 1.5rem;
  }
`;

const ProfilePage = () => {
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);

  const toggleAccordion = (section: string) => {
    setActiveAccordion(activeAccordion === section ? null : section);
  };

  return (
    <>
      <SEO
        title="About Ralph Brooks | Forte Notary"
        description="Learn more about Ralph Brooks, founder of Forte Notary, offering professional mobile notary services across Dallas-Fort Worth."
      />
      <div className={`about-page pt-5 bg-[#212A37] text-[#FFFFFC] min-h-screen flex flex-col `}>
        {/* Header Section */}
        <header className="fixed top-0 left-0 w-full p-4 flex justify-between items-center border-b border-[#FFFFFC] bg-[#212A37]/80 backdrop-blur z-[100]">
          <div className="hidden md:flex w-full">
            <AppHeaderFull />
          </div>
          <div className="md:hidden flex items-center justify-between w-full">
            <a href="/" className="text-[#FFFFFC] text-xl font-bold mr-auto">
              Forte Notary
            </a>
            <AppHeaderMobile />
          </div>
        </header>

       
          {/* Hero Section */}
          <section className={`hero-section ${backgroundStyle} relative`} >
            {/* Image behind profile section  url('/about/frisco-dark-background.webp')*/}
            {/* <div className="hero-image -z-10">
              <img src="/about/frisco-darkest-background-sharp-lossy.webp" alt="Frisco Water Tower" />
            </div> */}
            
            {/* Profile container */}
            <div className="profile-container container mx-auto px-4 py-8">

            {/* Profile Section */}
            <div className="profile-section pt-24 flex flex-col md:flex-row items-center md:items-start">
              {/* Profile Image */}
              <div className="md:w-1/3 flex justify-center md:justify-start">
                <img src="brooks-face.webp" alt="Ralph Brooks" className={`face-image ${profileImageStyle}`} />
              </div>
              {/* Profile Details */}
              <div className="md:w-2/3 md:pl-12 mt-6 md:mt-0 text-center md:text-left">
              
                <h1 className="text-4xl font-bold mb-2">Ralph Brooks</h1>
                <h2 className="text-2xl font-semibold mb-4">Business Leader & Mobile Notary</h2>
                <p className="text-xl mb-4">
                  Dedicated to providing tech-enhanced, professional notary services across Dallas-Fort Worth.
                </p>
                <div className={`contact-and-social ${contactAndSocialStyle}`}>
                {/* Contact Info */}
                <div className={contactInfoStyle}>
                  <p>
                    <strong>Email:</strong>{' '}
                    <a href="mailto:ralph.brooks@fortenotary.com">ralph.brooks@fortenotary.com</a>
                  </p>
                  <p>
                    <strong>Phone:</strong> <a href="tel:+14692968682">+1 (469) 296-8682</a>
                  </p>
                </div>
                {/* Social Media Links */}
                <div className={socialIcons}>
                  <a
                    href="https://www.linkedin.com/in/ralphbrooks"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </a>
                </div>
                </div>
                
              </div>
            </div>
            </div>

          </section>

          <main className={`flex-grow flex flex-col items-center ${contentWrapper}`}>
          <div className="container mx-auto px-4 py-8">
            {/* Main content section */}
            <section className={`main-content ${mainContentStyle}`}>
              {/* Overview Section */}
              
              <section className={`mt-12`}>
                <h3 className={sectionTitle}>Overview</h3>
                <p className="mt-4 text-lg leading-relaxed">
                  I leverage a deep background in technology and finance to offer fast, error-free notary services
                  for busy professionals across Dallas-Fort Worth. With experience managing notary services for
                  executive clients—including former leaders from BlackRock and Goldman Sachs—and an MBA from UCLA
                  Anderson, I understand the needs of elite clients.
                </p>
                <p className={`mt-4 text-lg leading-relaxed ${contactInfoStyle}`}>I have a current background check screening through the National Notary Association. Details about information that the NNA has verified about me is at <a href="https://www.signingagent.com/profile/161842286">https://www.signingagent.com/profile/161842286</a>  </p>
              </section>

              {/* Credentials Section */}
              <section className="mt-12">
                <h3 className={sectionTitle}>Credentials</h3>
                <div className={accordionSection}>
                  {/* Education Accordion */}
                  <div className={accordionItem}>
                    <div
                      className={accordionHeader}
                      onClick={() => toggleAccordion('education')}
                      role="button"
                      tabIndex={0}
                    >
                      <span>Education</span>
                      <span>{activeAccordion === 'education' ? '-' : '+'}</span>
                    </div>
                    {activeAccordion === 'education' && (
                      <div className={accordionContent}>
                        <ul className="list-disc list-inside">
                          <li>
                            <strong>UCLA Anderson School of Management</strong>, MBA
                          </li>
                          <li>
                            <strong>Central Michigan University</strong>, B.A., Summa Cum Laude
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Certifications Accordion */}
                  <div className={accordionItem}>
                    <div
                      className={accordionHeader}
                      onClick={() => toggleAccordion('certifications')}
                      role="button"
                      tabIndex={0}
                    >
                      <span>Certifications</span>
                      <span>{activeAccordion === 'certifications' ? '-' : '+'}</span>
                    </div>
                    {activeAccordion === 'certifications' && (
                      <div className={accordionContent}>
                        <ul className="list-disc list-inside">
                          <li>
                            <strong>National Notary Association</strong> - Passed NSA Certification Exam
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* Professional Background Accordion */}
                  <div className={accordionItem}>
                    <div
                      className={accordionHeader}
                      onClick={() => toggleAccordion('professional')}
                      role="button"
                      tabIndex={0}
                    >
                      <span>Professional Background</span>
                      <span>{activeAccordion === 'professional' ? '-' : '+'}</span>
                    </div>
                    {activeAccordion === 'professional' && (
                      <div className={accordionContent}>
                        <ul className="list-disc list-inside">
                          <li>
                            Four years of real estate finance experience with executive clients, including former
                            leaders from BlackRock and Goldman Sachs.
                          </li>
                          <li>
                            Extensive technology background with expertise in AI and web technologies.
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </section>

              {/* Specialization Section */}
              <section className="mt-12">
                <h3 className={sectionTitle}>Specializations</h3>
                <ul className="mt-4 text-lg leading-relaxed list-disc list-inside">
                  <li>
                    <strong>Loan Signing Services:</strong> Comprehensive handling of buyer packages, seller packages, and refinances.
                  </li>
                  <li>
                    <strong>Will Signing Services:</strong> Providing experienced oversight, guidance, and witness coordination to ensure a secure and legally valid will execution.
                  </li>
                  <li>
                    <strong>Mobility:</strong> Offering mobile notary services across the Dallas-Fort Worth Metroplex.
                  </li>

                </ul>
              </section>

            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default ProfilePage;
